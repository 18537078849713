import { useCartData } from "../../lib/cartData/useCartData";
import {
  CustomItemInput,
  ProductBasicInfoFragment,
  ProductInfoFragment,
  ProductType,
} from "../../generated/graphql";
import { BuildStateCategory } from "../../templates/types";
import cuid from "cuid";
import { prepareCartBuildState } from "../../Renderers/CustomProductRenderer";
import { useSearchParams } from "@/i18n/i18n-navigation";

interface BuyNowButtonProps {
  product:
    | ProductBasicInfoFragment
    | (Omit<ProductInfoFragment, "categories"> & {
        categories: BuildStateCategory[];
      });
  quantity?: number;
  buildState?: BuildStateCategory[] | undefined;
}

const HandleBuyNow = ({
  product,
  quantity = 1,
  buildState,
}: BuyNowButtonProps) => {
  const searchParams = useSearchParams();
  const selectionsQuery = searchParams.get("selections");

  const {
    replaceCartItemsWithCustomItem: { replaceCartItemsWithCustomItem },
    replaceCartItemsWithSimpleItem: { replaceCartItemsWithSimpleItem },
  } = useCartData();

  const selectedVariant =
    product?.variants?.nodes?.find(
      (variant) => variant.id === selectionsQuery
    ) || product?.variants?.nodes?.[0];

  return async () => {
    if (product.type === ProductType.Custom && buildState) {
      const buildUniqueId = `build-${product.id}-${cuid()}`;
      const dataToSave = prepareCartBuildState(
        buildState,
        product as Omit<ProductInfoFragment, "categories"> & {
          categories: BuildStateCategory[];
        },
        buildUniqueId
      );
      const newCustomItem: CustomItemInput = {
        productId: product.id,
        quantity: 1,
        selectedVariants: dataToSave.categories.map((category) => ({
          categoryId: category.id,
          quantity: category.selectedVariants[0].quantity,
          variantId: category.selectedVariants[0].id,
        })),
      };

      await replaceCartItemsWithCustomItem({ item: newCustomItem });
    } else {
      await replaceCartItemsWithSimpleItem({
        item: {
          quantity,
          variantId: selectedVariant?.id!,
        },
      });
    }
  };
};

export default HandleBuyNow;
