import React, { Suspense } from "react";
import styled, { css } from "styled-components";
import { FormattedMessage, useLocale } from "@/i18n/i18n-client";
import { BadgeContainer, Badge as OldBadge } from "@/components/Badge/Badge";
import { PrimaryButton } from "@/components/Button";
import { Price, DiscountPrice } from "@/components/Price";
import { BuyNowButtonDisplay, ProductType } from "@/generated/graphql";
import { FlexCol, H3, Spinner } from "@/shared/globals";
import { themeColor } from "@/shared/styles-utils";
import type { ProductCardProps } from "../../../../types";
import Alert from "@/shared/globals/UiElements/Alert";
import { useStore } from "@/lib/storeData/StoreContext";
import ProductCardReviews from "../../ReviewsAndRatings/ProductCardReviews";
import BuyNowButton from "@/components/BuyNowButton/BuyNowButton";
import ItemImg from "@/shared/globals/UiElements/ItemImg";
import Flex from "@/shared/globals/UiElements/Flex";
import { getTranslatedDiscountTitle } from "@/shared/utils/getTranslatedDiscountTitle";
import { Badge } from "@/shared/globals/UiElements/Badge";
import { Link } from "@/i18n/i18n-navigation";
import { CouponIcon } from "@/assets/Icons/CouponIcon";
import { ShoppingCart } from "@/assets/Icons/ShoppingCart";

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  collection,
  isAlertOpen,
  setIsAlertOpen,
  handleAddToCart,
  isDiscount,
  discountedCustomProductPrice,
  availableQuantity,
  isSlider,
  isCartUpdating,
  isProductList = false,
  appliedAutomaticDiscounts,
  isAddingSimpleItem,
}) => {
  const {
    currency: currencyCode,
    areReviewsActivated,
    appearance,
  } = useStore();
  const { code: activeLocale } = useLocale();

  const productTitle = product?.title?.substring(0, 63);
  const firstVariant = product?.variants?.nodes[0];
  const customProduct = product.type === ProductType.Custom;
  const productHasOptions =
    !customProduct && !!firstVariant?.selectedOptions?.length;
  const isButNowButtonEnabled =
    !!appearance?.productDisplay?.buyNowButton?.display &&
    appearance?.productDisplay?.buyNowButton?.display !==
      BuyNowButtonDisplay.None;
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <ProductCardContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isProductList={isProductList}
    >
      <StyledLink
        href={`/product/${collection || "all"}/${product.handle}`}
        fullWidth
      >
        <BadgeContainer>
          <OldBadge
            outOfStock={!product.isInStock}
            onSale={
              !!firstVariant?.compareAtPrice &&
              firstVariant?.price?.amount !== 0
            }
            customInStock={customProduct && !!product.isInStock}
          />
          {isDiscount && (
            <OldBadge customItemDiscount={product?.discount ?? undefined} />
          )}
          {!!appliedAutomaticDiscounts?.length && (
            <Flex flexWrap="wrap" spacing="xs">
              {appliedAutomaticDiscounts.map((discount) => (
                <Badge
                  key={discount?.id}
                  type="ink"
                  label={
                    <Flex alignItems="center" spacing="xs">
                      <CouponIcon />
                      {getTranslatedDiscountTitle({
                        defaultTitle: discount?.title,
                        currentLocale: activeLocale,
                        translations: discount?.translations,
                      })}
                    </Flex>
                  }
                />
              ))}
            </Flex>
          )}
        </BadgeContainer>

        <ItemImg imgDetails={product.images?.[0]} onlyImg maxHeight="300px" />
      </StyledLink>
      <InfoWrapper isSlider={isSlider}>
        <Link href={`/product/${collection || "all"}/${product.handle}`}>
          <Title data-test="text-product-title">{productTitle}</Title>
        </Link>
        {areReviewsActivated && (
          <ProductCardReviews
            averageRating={product?.reviewsStatistics?.average}
            totalReviews={product?.reviewsStatistics?.total}
          />
        )}
        <div>
          <PriceWrapper>
            {!customProduct ? (
              <>
                {firstVariant?.price?.amount === 0 ? (
                  <H3 color="primary" fontWeight={700}>
                    <FormattedMessage defaultMessage="Free" id="tf1lIh" />
                  </H3>
                ) : (
                  <>
                    <Price money={firstVariant?.price} />
                    {firstVariant?.compareAtPrice && (
                      <DiscountPrice money={firstVariant?.compareAtPrice} />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {isDiscount ? (
                  <>
                    <Price
                      money={{
                        amount: discountedCustomProductPrice!,
                        currencyCode,
                      }}
                    />
                    <DiscountPrice money={product?.initialPrice} />
                  </>
                ) : (
                  <Price money={product?.initialPrice} />
                )}
              </>
            )}
          </PriceWrapper>
          <FlexCol spacing="xs">
            {isAddingSimpleItem ? (
              <AddButton
                fullWidth
                suffixIcon={<Spinner inline={true} size={14} />}
                style={{ cursor: "not-allowed" }}
              >
                <ButtonText>
                  <FormattedMessage defaultMessage="Loading" id="iFsDVR" />
                </ButtonText>
              </AddButton>
            ) : (
              <AddButton
                data-test="button-add-product-to-cart"
                fullWidth
                suffixIcon={
                  !productHasOptions && !customProduct && <ShoppingCart />
                }
                size="medium"
                onClick={
                  !productHasOptions && !customProduct
                    ? handleAddToCart
                    : undefined
                }
                disabled={!product.isInStock || isCartUpdating}
                style={{
                  cursor:
                    !product.isInStock || isCartUpdating
                      ? "not-allowed"
                      : "pointer",
                }}
              >
                {customProduct ? (
                  <Link
                    fullWidth
                    href={`/product/${collection || "all"}/${product.handle}`}
                  >
                    <ButtonText>
                      <FormattedMessage
                        defaultMessage="Build your product"
                        id="bnqhqf"
                      />
                    </ButtonText>
                  </Link>
                ) : productHasOptions ? (
                  <Link
                    fullWidth
                    href={`/product/${collection || "all"}/${product.handle}`}
                  >
                    <ButtonText>
                      <FormattedMessage
                        defaultMessage="Select options"
                        id="OBPen4"
                      />
                    </ButtonText>
                  </Link>
                ) : (
                  <ButtonText>
                    <FormattedMessage
                      defaultMessage="Add to cart"
                      id="ADKef8"
                    />
                  </ButtonText>
                )}
              </AddButton>
            )}
            {isButNowButtonEnabled && (
              <Suspense>
                <BuyNowButton
                  isDisabled={
                    !product.isInStock ||
                    productHasOptions ||
                    customProduct ||
                    (!!firstVariant?.cartLimitsEnabled &&
                      firstVariant?.minPerCart! !== 1)
                  }
                  isHovered={isHovered}
                  isAlwaysVisible={false}
                  isCard
                  product={product}
                />
              </Suspense>
            )}
          </FlexCol>
        </div>
        {isAlertOpen && (
          <Alert
            message={
              availableQuantity?.type === "stock" ? (
                <FormattedMessage
                  defaultMessage="Quantity exceeded"
                  id="zeofy+"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="You can only add up to {max} of this product to cart"
                  id="My3qar"
                  values={{ max: availableQuantity?.max }}
                />
              )
            }
            closeAction={() => setIsAlertOpen!(false)}
          />
        )}
      </InfoWrapper>
    </ProductCardContainer>
  );
};

export default ProductCard;

/**
 *
 * Styles
 *
 */

const StyledLink = styled(Link)`
  display: flex;
`;

const ProductCardContainer = styled.div<{
  isProductList?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  ${({ isProductList }) =>
    isProductList &&
    css`
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    `};
`;

const InfoWrapper = styled.div<{ isSlider?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 1 auto;
  padding: ${({ isSlider }) => (isSlider ? "10px 0" : "10px")};
  width: 100%;
  box-sizing: border-box;
`;

const Title = styled(H3)`
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  margin: 14px 0 20px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const AddButton = styled(PrimaryButton)`
  font-size: 12px;
  padding: 5px;
  color: ${themeColor("primary")};
  border: ${({ theme }) => `solid 1px ${theme.space.border}`};
  background-color: transparent;
  min-height: 40px;
  @media (min-width: 768px) {
    padding: 6px 0;
    font-size: 14px;
  }
  svg {
    height: 18px;
  }

  div {
    @media (min-width: 768px) {
      padding: 0 4px;
    }
    padding: 0;
  }
`;

const ButtonText = styled.span`
  padding: 0 4px;
`;
