import React from "react";
import MotionElementWrapper, {
  MotionElementWrapperProps,
} from "./MotionElementWrapper";

interface WhileInViewAnimationProps extends MotionElementWrapperProps {
  children: React.ReactNode;
  isDisabled?: boolean;
  amount?: number;
}

const WhileInViewAnimation = ({
  children,
  isDisabled,
  amount = 0.1,
  ...props
}: WhileInViewAnimationProps) => {
  return (
    <MotionElementWrapper
      initial={
        isDisabled
          ? {
              opacity: 1,
              y: 0,
            }
          : {
              opacity: 0,
              y: 20,
            }
      }
      whileInView={{
        opacity: 1,
        y: 0,
      }}
      viewport={{ once: true, amount }}
      fullHeight
      fullWidth
      transition={{
        delay: 0.2,
        ...props.transition,
      }}
      {...props}
    >
      {children}
    </MotionElementWrapper>
  );
};

export default WhileInViewAnimation;
