import cuid from "cuid";
import React from "react";
import {
  CartCustomItem,
  CustomProductDiscount,
  Money,
  ProductBasicInfoFragment,
  ProductInfoFragment,
  ProductStatus,
} from "../generated/graphql";
import type {
  BuildState,
  BuildStateVariant,
  CartBuildData,
  CustomProductInfoProps,
} from "../templates/types";
import type { RendererComponent } from "./types";
import { useCartData } from "../lib/cartData/useCartData";
import { useSearchParams } from "@/i18n/i18n-navigation";

interface CustomProductRendererProps
  extends RendererComponent<CustomProductInfoProps> {}

const CustomProductRenderer: React.FC<CustomProductRendererProps> = ({
  Component,
  product,
}) => {
  const searchParams = useSearchParams();
  const itemIdQuery = searchParams.get("itemId");
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const firstCategoryId = product.categories[0]?.id;
  const [activeCategoryId, setActiveCategoryId] = React.useState<string | null>(
    firstCategoryId
  );
  const {
    addCustomItem: { addCustomItem },
    cart,
    updateCustomItem: { updateCustomItem },
    setSideCartState,
  } = useCartData();
  const [isSummary, setIsSummary] = React.useState(false);
  const [exceededVariantId, setExceededVariantId] = React.useState("");
  const [buildState, setBuildState] = React.useState<BuildState>();

  const cartItems = cart?.items;
  React.useEffect(() => {
    if (window.innerWidth <= 768) {
      setActiveCategoryId(null);
      return;
    }
    setActiveCategoryId(firstCategoryId);
  }, [firstCategoryId]);

  const [isUpdating, setIsUpdating] = React.useState(false);
  React.useEffect(() => {
    const buildItemFromCart = cartItems?.find(
      (item) => item.id === itemIdQuery
    ) as CartCustomItem;
    const buildDataFromCart = buildItemFromCart?.categories;
    setIsUpdating(!!buildDataFromCart);

    const buildTree = getSavedBuildState(product, buildDataFromCart);
    setBuildState(itemIdQuery ? buildTree : prepareDefaultBuildState(product));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemIdQuery]);

  const buildSubtotal = computeBuildSubtotal(buildState);
  const isDiscount = !!(product?.discount?.percent || product?.discount?.fixed);
  const buildTotal = computeBuildTotal(product, buildState);

  const handleAddToCart = async () => {
    if (!buildState) return;

    const buildUniqueId = `build-${product.id}-${cuid()}`;

    const dataToSave = prepareCartBuildState(
      buildState,
      product,
      buildUniqueId
    );

    const newCustomItem = {
      productId: dataToSave.id,
      quantity: 1,
      selectedVariants: dataToSave.categories.flatMap((category) =>
        category.selectedVariants.map((variant) => ({
          categoryId: category.id,
          quantity: variant.quantity,
          variantId: variant.id,
        }))
      ),
    };

    if (isUpdating) {
      await updateCustomItem({
        item: {
          productId: dataToSave.id,
          quantity:
            cart?.items?.find((item) => item.id === itemIdQuery)?.quantity || 1,
          selectedVariants: newCustomItem.selectedVariants,
        },
      });

      setSideCartState(true);
    } else {
      await addCustomItem({ item: newCustomItem });
    }

    setIsAlertOpen(false);
    setIsSummary(false);
  };

  if (!buildState) {
    return null;
  }

  return (
    <Component
      product={product}
      buildState={buildState}
      isSummary={isSummary}
      activeCategoryId={activeCategoryId!}
      buildTotal={buildTotal}
      buildSubtotal={buildSubtotal}
      isDiscount={isDiscount}
      isAlertOpen={isAlertOpen}
      exceededVariantId={exceededVariantId}
      setExceededVariantId={setExceededVariantId}
      setIsAlertOpen={setIsAlertOpen}
      handleAddToCart={handleAddToCart}
      prepareCartBuildState={prepareCartBuildState}
      setBuildState={setBuildState}
      setIsSummary={setIsSummary}
      setActiveCategoryId={setActiveCategoryId}
      isLoading={cart?.isLoading}
      isUpdating={isUpdating}
    />
  );
};

export default CustomProductRenderer;

/**
 *
 * Functions
 *
 */

function getFixedDiscount(fixedDiscount: Money | undefined | null) {
  if (fixedDiscount?.amount && fixedDiscount?.amount > 0) {
    return {
      currencyCode: fixedDiscount?.currencyCode!,
      amount: fixedDiscount?.amount,
    };
  }

  return null;
}

function getDiscount(discount: CustomProductDiscount | undefined | null) {
  if (discount?.fixed?.amount || discount?.percent) {
    return {
      fixed: getFixedDiscount(discount.fixed),
      percent: discount?.percent,
    };
  }
  return undefined;
}

function getImage(variant: BuildStateVariant) {
  if (
    variant?.variant?.image?.id &&
    variant?.variant?.product?.images?.[0]?.id
  ) {
    return {
      id:
        variant?.variant?.image?.id ||
        variant?.variant?.product?.images?.[0]?.id,
      src:
        variant?.variant?.image?.src ||
        variant?.variant?.product?.images?.[0]?.src,
      altText:
        variant?.variant?.image?.altText ||
        variant?.variant?.product?.images?.[0]?.altText,
    };
  }
  return null;
}

function filterDraftVariants(variants: BuildStateVariant[]) {
  return variants?.filter(
    (variant) =>
      variant?.variant &&
      variant?.variant?.product?.status !== ProductStatus.Draft
  );
}

function prepareDefaultBuildState(
  product: CustomProductInfoProps["product"]
): BuildState {
  return product.categories.map((category) => {
    let preSelectedVariant = category.variants.findIndex((variant) => {
      return (
        variant.preselected &&
        (!variant.variant.trackQuantity || (variant.variant.quantity ?? 0) > 0)
      );
    });

    preSelectedVariant =
      preSelectedVariant >= 0
        ? preSelectedVariant
        : category.variants.findIndex((variant) => {
            return (
              !variant.variant.trackQuantity ||
              (variant.variant.quantity ?? 0) > 0
            );
          });

    return {
      ...category,
      isCompleted: category.variants.some((variant) => variant?.preselected),
      variants: filterDraftVariants(category.variants).map((variant, idx) => {
        return {
          ...variant,
          variant: {
            ...variant.variant,
            isSelected: preSelectedVariant === idx,
            selectedQuantity: variant.preselected ? 1 : 0,
            image: getImage(variant),
          },
        };
      }),
    };
  });
}

function getSavedBuildState(
  product: CustomProductInfoProps["product"],
  savedData: any
): BuildState | undefined {
  try {
    const categoriesMap = savedData?.reduce(
      (accumulator, { category, selectedVariants }) => {
        accumulator[category.id] = selectedVariants;
        return accumulator;
      },
      {}
    );

    return product.categories.map((category) => {
      const filterVariants = filterDraftVariants(category.variants);

      const preSelectedVariantByDefaultIndex = filterVariants.findIndex(
        (variant) => variant?.preselected
      );

      const preSelectedVariantByDefault =
        filterVariants[preSelectedVariantByDefaultIndex];

      const inStockVariantIndex = filterVariants.findIndex(
        (variant) =>
          !variant?.variant?.quantity || variant?.variant?.quantity > 0
      );

      const preSelectedVariantIndex =
        !preSelectedVariantByDefault?.variant?.quantity ||
        preSelectedVariantByDefault?.variant?.quantity! > 0
          ? preSelectedVariantByDefaultIndex
          : inStockVariantIndex;

      return {
        ...category,
        isCompleted:
          (categoriesMap && !!categoriesMap[category.id]?.length) ||
          preSelectedVariantIndex > -1,
        variants: filterVariants.map((variant, index) => {
          const savedVariant =
            categoriesMap &&
            categoriesMap[category.id]?.find(
              (item) => item?.variant.id === variant?.variant?.id
            );

          return {
            ...variant,
            variant: {
              ...variant.variant,
              isSelected: categoriesMap
                ? !!savedVariant
                : index === preSelectedVariantIndex,
              selectedQuantity: +(savedVariant?.quantity || 1),
              image: getImage(variant),
            },
          };
        }),
      };
    });
  } catch (err) {
    return undefined;
  }
}

export function prepareCartBuildState(
  buildState: BuildState,
  product: CustomProductInfoProps["product"],
  buildUniqueId?: string
): CartBuildData {
  const buildCategories = buildState
    ?.filter((category) => category?.isCompleted)
    ?.map((category) => {
      const selectedVariantsForEachCategory = category?.variants
        ?.filter((variant) => variant?.variant?.isSelected)
        ?.map((selectedVariant) => ({
          img:
            selectedVariant?.variant?.image ||
            selectedVariant.variant?.product?.images?.[0],
          maxQuantity:
            selectedVariant?.variant?.quantity ?? Number.POSITIVE_INFINITY,
          price: selectedVariant?.variant?.price,
          sku: selectedVariant?.variant?.sku,
          productId: selectedVariant?.variant?.product?.id,
          quantity: selectedVariant?.variant?.selectedQuantity,
          selectedOptions: selectedVariant?.variant?.selectedOptions,
          title: selectedVariant?.variant?.product?.title,
          id: selectedVariant?.variant?.id,
        }));
      return {
        selectedVariants: selectedVariantsForEachCategory,
        id: category?.id,
        name: category?.name,
        img: category?.image,
        type: category?.categoryType,
      };
    });

  const currencyCode =
    buildState?.[0]?.variants?.[0]?.variant?.price?.currencyCode;
  const buildSubtotal = {
    currencyCode,
    amount: computeBuildSubtotal(buildState),
  };
  const buildTotal = {
    currencyCode,
    amount: computeBuildTotal(product, buildState),
  };

  return {
    id: product.id,
    handle: product.handle,
    buildId: buildUniqueId,
    title: product.title,
    img: product.images?.[0],
    discount: getDiscount(product?.discount!),
    subtotal: buildSubtotal,
    total: buildTotal,
    categories: buildCategories,
  };
}

export function computeBuildTotal(
  product:
    | CustomProductInfoProps["product"]
    | ProductBasicInfoFragment
    | ProductInfoFragment,
  buildState?: BuildState
): number {
  const discountByPercentage = product?.discount?.percent;
  const discountByFixedAmount = product?.discount?.fixed;
  const isDiscount = !!(discountByPercentage || discountByFixedAmount);
  const buildSubtotal = buildState
    ? computeBuildSubtotal(buildState)
    : product?.initialPrice?.amount!; // for ProductCard before having any buildState

  const buildTotal = !isDiscount
    ? buildSubtotal
    : discountByPercentage
      ? buildSubtotal - buildSubtotal * (discountByPercentage / 100)
      : buildSubtotal - (discountByFixedAmount?.amount || 0);
  return buildTotal < 0 ? 0 : buildTotal;
}

function computeBuildSubtotal(buildState: BuildState | undefined) {
  if (!buildState) return 0;
  return buildState?.reduce(
    (acc, category) =>
      acc +
      category.variants
        ?.filter((variant) => variant.variant.isSelected)
        .reduce(
          (sum, variant) =>
            sum +
            variant.variant.price.amount * variant.variant.selectedQuantity,
          0
        ),
    0
  );
}
