import React, { Suspense } from "react";
import { FormattedMessage, useLocale } from "@/i18n/i18n-client";
import styled from "styled-components";
import { ShoppingCart } from "@/assets/Icons/ShoppingCart";
import { ProductPlaceHolder } from "@/assets/Icons/ProductPlaceHolder";
import { CouponIcon } from "@/assets/Icons/CouponIcon";
import { Badge as OldBadge } from "@/components/Badge/Badge";
import { PrimaryButton } from "@/components/Button";
import { Price, DiscountPrice } from "@/components/Price";
import { BuyNowButtonDisplay, ProductType } from "@/generated/graphql";
import { useStore } from "@/lib/storeData/StoreContext";
import { P, FlexCol, Spinner } from "@/shared/globals";
import Alert from "@/shared/globals/UiElements/Alert";
import ProductCardReviews from "../../default/components/ReviewsAndRatings/ProductCardReviews";
import type { ProductCardProps } from "../../types";
import BuyNowButton from "@/components/BuyNowButton/BuyNowButton";
import { themeColor } from "@/shared/styles-utils";
import { Photo } from "@/shared/globals/UiElements/Photo";
import Flex from "@/shared/globals/UiElements/Flex";
import { getTranslatedDiscountTitle } from "@/shared/utils/getTranslatedDiscountTitle";
import { Badge } from "@/shared/globals/UiElements/Badge";
import { Link } from "@/i18n/i18n-navigation";

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  collection,
  isAlertOpen,
  handleAddToCart,
  setIsAlertOpen,
  isDiscount,
  discountedCustomProductPrice,
  availableQuantity,
  isSlider,
  isCartUpdating,
  isProductList,
  appliedAutomaticDiscounts,
  isAddingSimpleItem,
}) => {
  const {
    currency: currencyCode,
    areReviewsActivated,
    appearance,
  } = useStore();
  const { code: activeLocale } = useLocale();
  const productTitle = product?.title?.substring(0, 58);
  const firstVariant = product?.variants?.nodes[0];
  const customProduct = product.type === ProductType.Custom;
  const productHasOptions =
    !customProduct && !!firstVariant?.selectedOptions?.length;
  const isButNowButtonEnabled =
    !!appearance?.productDisplay?.buyNowButton?.display &&
    appearance?.productDisplay?.buyNowButton?.display !==
      BuyNowButtonDisplay.None;
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <ProductCardContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isProductList={isProductList}
    >
      <BadgeContainer alignItems="flex-start">
        <OldBadge
          outOfStock={!product.isInStock}
          onSale={
            !!firstVariant?.compareAtPrice && firstVariant?.price?.amount !== 0
          }
          customInStock={customProduct && !!product.isInStock}
        />
        {isDiscount && (
          <OldBadge customItemDiscount={product?.discount ?? undefined} />
        )}
        {!!appliedAutomaticDiscounts?.length && (
          <Flex flexWrap="wrap" spacing="xs">
            {appliedAutomaticDiscounts.map((discount) => (
              <Badge
                key={discount?.id}
                type="ink"
                label={
                  <Flex alignItems="center" spacing="xs">
                    <CouponIcon />
                    {getTranslatedDiscountTitle({
                      defaultTitle: discount?.title,
                      currentLocale: activeLocale,
                      translations: discount?.translations,
                    })}
                  </Flex>
                }
              />
            ))}
          </Flex>
        )}
      </BadgeContainer>

      <Link
        fullWidth
        fullHeight
        href={`/product/${collection || "all"}/${product.handle}`}
      >
        <P
          data-test="text-product-title"
          style={{ flexGrow: 1 }}
          margin="0 0 10px 0"
        >
          {productTitle}
        </P>
        <ImageContainer>
          {product.images.length === 0 ? (
            <span style={{ position: "absolute" }}>
              <ProductPlaceHolder height="300px" />
            </span>
          ) : (
            <Photo
              data-test="product-image"
              src={product?.images?.[0]?.src || ""}
              alt={product.title}
              objectFit="contain"
              absolute
            />
          )}
        </ImageContainer>
        {areReviewsActivated && (
          <ProductCardReviews
            averageRating={product?.reviewsStatistics?.average}
            totalReviews={product?.reviewsStatistics?.total}
          />
        )}
        <PriceWrapper>
          {!customProduct ? (
            <>
              <Price money={firstVariant?.price} />
              {firstVariant?.compareAtPrice && (
                <DiscountPrice money={firstVariant?.compareAtPrice} />
              )}
            </>
          ) : (
            <>
              {isDiscount ? (
                <>
                  <Price
                    money={{
                      amount: discountedCustomProductPrice!,
                      currencyCode,
                    }}
                  />
                  <DiscountPrice money={product?.initialPrice} />
                </>
              ) : (
                <Price money={product?.initialPrice} />
              )}
            </>
          )}
        </PriceWrapper>
      </Link>

      <FlexCol
        style={{
          boxSizing: "border-box",
        }}
        spacing="xs"
      >
        {isAddingSimpleItem ? (
          <AddButton
            fullWidth
            suffixIcon={<Spinner inline={true} size={14} />}
            style={{ cursor: "not-allowed" }}
          >
            <FormattedMessage defaultMessage="Loading" id="iFsDVR" />
          </AddButton>
        ) : (
          <AddButton
            data-test="button-add-product-to-cart"
            fullWidth
            suffixIcon={
              !productHasOptions && !customProduct && <ShoppingCart />
            }
            size="medium"
            onClick={
              !productHasOptions && !customProduct ? handleAddToCart : undefined
            }
            disabled={!product.isInStock || isCartUpdating}
          >
            {customProduct ? (
              <Link
                fullWidth
                href={`/product/${collection || "all"}/${product.handle}`}
              >
                <ButtonText>
                  <FormattedMessage
                    defaultMessage="Build your product"
                    id="bnqhqf"
                  />
                </ButtonText>
              </Link>
            ) : productHasOptions ? (
              <Link
                fullWidth
                href={`/product/${collection || "all"}/${product.handle}`}
              >
                <ButtonText>
                  <FormattedMessage
                    defaultMessage="Select options"
                    id="OBPen4"
                  />
                </ButtonText>
              </Link>
            ) : (
              <ButtonText>
                <FormattedMessage defaultMessage="Add to cart" id="ADKef8" />
              </ButtonText>
            )}
          </AddButton>
        )}
        {isButNowButtonEnabled && (
          <Suspense>
            <BuyNowButton
              isDisabled={
                !product.isInStock ||
                productHasOptions ||
                customProduct ||
                (!!firstVariant?.cartLimitsEnabled &&
                  firstVariant?.minPerCart! !== 1)
              }
              isHovered={isHovered}
              isAlwaysVisible={false}
              isSlider={isSlider}
              isCard
              product={product}
            />
          </Suspense>
        )}
      </FlexCol>
      {isAlertOpen && (
        <Alert
          message={
            availableQuantity?.type === "stock" ? (
              <FormattedMessage
                defaultMessage="Quantity exceeded"
                id="zeofy+"
              />
            ) : (
              <FormattedMessage
                defaultMessage="You can only add up to {max} of this product to cart"
                id="My3qar"
                values={{ max: availableQuantity?.max }}
              />
            )
          }
          closeAction={() => setIsAlertOpen!(false)}
        />
      )}
    </ProductCardContainer>
  );
};

export default ProductCard;

/**
 *
 * Styles
 *
 */

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  margin: 14px 0 20px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  max-height: 300px;
  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

const AddButton = styled(PrimaryButton)`
  font-size: 12px;
  padding: 5px;
  color: ${themeColor("primary")};
  border: ${({ theme }) => `solid 1px ${theme.space.border}`};
  background-color: transparent;
  min-height: 40px;
  @media (min-width: 768px) {
    padding: 6px 0;
    font-size: 14px;
  }
  svg {
    height: 18px;
  }

  div {
    @media (min-width: 768px) {
      padding: 0 4px;
    }
    padding: 0;
  }
`;

const ProductCardContainer = styled.div<{
  isProductList?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 10px;
  &:hover {
    box-shadow: ${({ isProductList }) =>
      isProductList ? "0px 0px 10px 6px rgba(0, 0, 0, 0.10)" : "none"};
  }
  a {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`;

const ButtonText = styled.span`
  padding: 0 4px;
`;

const BadgeContainer = styled(FlexCol)`
  margin-bottom: 20px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
