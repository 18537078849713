import { FormattedMessage } from "@/i18n/i18n-client";
import {
  ProductBasicInfoFragment,
  ProductInfoFragment,
} from "../../../../generated/graphql";
import { useStore } from "@/lib/storeData/StoreContext";
import { BuildStateCategory } from "../../../../templates/types";
import { CustomButton } from "../../components/Button/CustomButton";
import useBreakPoints from "../../../../shared/utils/useBreakPoints";
import { useCartData } from "../../../../lib/cartData/useCartData";
import { useState } from "react";
import HandleBuyNow from "../../../../shared/utils/handleBuyNow";
import BuyNowConfirmModal from "../../../../shared/globals/UiElements/BuyNowConfirmModal";

interface BuyNowButtonProps {
  product:
    | ProductBasicInfoFragment
    | (Omit<ProductInfoFragment, "categories"> & {
        categories: BuildStateCategory[];
      });
  isDisabled?: boolean;
  quantity?: number;
  buildState?: BuildStateCategory[] | undefined;
  borderRadius?: number;
}

const BuyNowButton = ({
  product,
  isDisabled,
  quantity = 1,
  buildState,
  borderRadius,
}: BuyNowButtonProps) => {
  const { appearance } = useStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { cart } = useCartData();
  const { isTablet } = useBreakPoints();

  const handleBuyNow = HandleBuyNow({
    buildState,
    product,
    quantity,
  });
  return (
    <>
      <CustomButton
        data-test="button-buy-now"
        fullWidth
        isDisabled={isDisabled}
        customDefault={{
          backgroundColor:
            appearance?.productDisplay?.buyNowButton?.backgroundColor!,
          borderColor: appearance?.productDisplay?.buyNowButton?.borderColor!,
          color: appearance?.productDisplay?.buyNowButton?.fontColor!,
        }}
        borderRadius={borderRadius}
        onClick={() =>
          cart?.items?.length ? setIsModalOpen(true) : handleBuyNow()
        }
        className="h-full"
        fontSize={isTablet ? 14 : 16}
      >
        <FormattedMessage defaultMessage="Buy Now" id="lCILNz" />
      </CustomButton>
      <BuyNowConfirmModal
        onClose={() => setIsModalOpen(false)}
        productName={product.title}
        show={isModalOpen}
        handleBuyNow={handleBuyNow}
        loading={cart?.isLoading}
      />
    </>
  );
};

export default BuyNowButton;
